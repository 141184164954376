import "bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import "./import-jquery";

$(function() {
  var $window = $(window),
      $body = $("body"),
      h = $window.height();
  var scrolled = false;

  function checkScroll() {
    var st = $window.scrollTop();
    if (st > h / 3 && !scrolled) {
      $body.addClass("scrolled");
      scrolled = true;
    } else if (st < h/3 && scrolled) {
      $body.removeClass("scrolled");
      scrolled = false;
    }
  }

  $window.scroll(checkScroll);
  checkScroll();

  $(document).on('keydown', function(event) {
    if (event.key === "Escape") {
      $("html").removeClass("scroll");
      $(".popup-container").removeClass("visible");
    }
  });

  $("a.popup-anchor").click(function(e) {
    e.preventDefault();

    const target = $(this).attr("data-target");

    if (target) {
      $("html").addClass("scroll");
      $("#" + target).addClass("visible");
    }
  });

  $(".popup-back").click(function(e) {
    e.preventDefault();


    $("html").removeClass("scroll");
    $(".popup-container").removeClass("visible");
  });
})

